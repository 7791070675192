$yellow: #ffdc01;
$orange:#FDAC01;
$red: #f73b01;

.App {
  position: relative;
  text-align: center;
  margin-top: 10vh;
  h1 {
    color: white;
    text-shadow: 0 0 10px rgba($orange,1);
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      a {
        display: block;
        font-size: 1.2rem;
        color: mix($yellow, $orange, 35%);
        text-decoration: none;
        transition: .25s ease-out;
        padding: .25rem;
        &:hover, &:focus {
          color: mix($red, $orange, 75%);
          //scale: 1.05;
        }
      }
    }
  }
}