$backgroundColor: darken(rgb(97, 89, 87), 20%);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $backgroundColor;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items:center;
  &:before {
    content: '';
    display: block;
    background: linear-gradient(180deg, darken($backgroundColor, 10) 0%, $backgroundColor 100%) $backgroundColor;
    height: 800px;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 0;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
